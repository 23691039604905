export const basePath =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5000/v1/api"
    : "https://api.tokdownload.app/v1/api";

// export const NEXT_PUBLIC_PATH =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3001"
//     : "https://portal.tokdownload.app";

export const NEXT_PUBLIC_PATH = process.env.REACT_APP_PORTAL_URL

export const APIurls = {
  // auth
  login: "auth/login",
  register: "auth/register",
  authenticate: "auth/authenticate",
  logout: "auth/logout",
  reset: "auth/reset-password",
  forget: "auth/forgot-password"
};